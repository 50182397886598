import { Grid, styled, TableCell, TableRow } from '@cmg/design-system';

export type HeaderProps = {
  align?: 'left' | 'center' | 'right' | 'justify';
  width?: string;
  minWidth?: number;
  rightBorder?: boolean;
};

export type RowProps = {
  align?: 'inherit' | 'left' | 'center' | 'right' | 'justify';
  topDivider?: boolean;
  topBorder?: boolean;
  rightBorder?: boolean;
  isTotals?: boolean;
  blur?: string;
  noPadding?: boolean;
  showCursor?: boolean;
  noBorderBottom?: boolean;
};

export const totalRowStyle = {
  td: { typography: 'highlight1', borderBottom: 'none' },
};

export const gridStyle = { width: '100%', overflowX: 'auto' };
export const tableContainerStyle = { minWidth: theme => theme.spacing(110), overflowX: 'initial' };
export const StyledGrid = styled(Grid)`
  width: 100%;
  overflow-x: auto;
`;

export const StyledTableHeader = styled(TableCell)<HeaderProps>`
  text-align: ${({ align }) => align ?? 'right'};
  min-width: ${({ minWidth }) => minWidth ?? 0}px;
  white-space: pre-line;

  ${({ theme, rightBorder }) =>
    rightBorder &&
    `
      border-right: 1px solid ${theme.palette.divider};
      &::after {
        content: none;
      }
    `}
`;

export const StyledTableRow = styled(TableRow)<RowProps>`
  text-align: ${({ align }) => align ?? 'right'} !important;

  ${({ topDivider }) =>
    topDivider &&
    `
      border-top: solid;
      border-top-color: darkgrey;
    `}

  ${({ topBorder }) =>
    topBorder &&
    `
      border-top: 1px solid rgb(224, 224, 224);
    `}

  ${({ showCursor }) =>
    showCursor &&
    `
      cursor: pointer;
    `}
      
  ${({ theme, isTotals }) =>
    isTotals
      ? `
      background-color: #EDF1F9;
    `
      : `
      background-color: ${theme.palette.background.default};
    `}
`;

export const StyledTableCell = styled(TableCell)<RowProps>`
  text-align: ${({ align }) => align ?? 'right'};
  padding: ${({ noPadding }) => (noPadding ? '0px' : '6px 8px')};

  ${({ theme, rightBorder }) =>
    rightBorder &&
    `
      border-right: 1px solid ${theme.palette.divider};
      &::after {
        content: none;
      }
    `}

  ${({ noBorderBottom }) =>
    noBorderBottom &&
    `
      border-bottom: none;
    `}

  ${({ blur }) =>
    `
      filter: ${blur ? `blur(${blur})` : null};
    `}
`;

export const SNoDataAvailable = styled(TableCell)`
  color: rgb(3, 5, 7, 0.55);
  min-height: 42px;
  border-bottom: none;
`;
