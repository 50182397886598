import React, { PropsWithChildren } from 'react';

import theme from '../../../styles/theme';
import { SContent, SWrapper } from './Tag.styles';

export type Props = PropsWithChildren<{
  testId?: string;
  className?: string;
  color?: string;
  onMouseEnter?: (event: React.MouseEvent<HTMLDivElement>) => void;
  onMouseLeave?: (event: React.MouseEvent<HTMLDivElement>) => void;
  outline?: boolean;
  variant?: 'DEFAULT' | 'PILL';
  innerRef?: React.Ref<HTMLDivElement>;
}>;

const TagComponent: React.FC<Props> = ({
  children,
  color = theme.brand.color.light,
  onMouseEnter,
  onMouseLeave,
  className,
  outline = false,
  variant = 'DEFAULT',
  testId,
  innerRef,
}) => {
  return (
    <SWrapper
      ref={innerRef}
      data-test-id={testId}
      color={color}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      className={className}
      outline={outline}
      variant={variant}
    >
      <SContent>{children}</SContent>
    </SWrapper>
  );
};

const Tag = React.forwardRef<HTMLDivElement, Props>((props, ref) => (
  <TagComponent {...props} innerRef={ref} />
));
Tag.displayName = 'Tag';

export default Tag;
