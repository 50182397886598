import { Grid, InfoOutlinedIcon, Tooltip, Typography } from '@cmg/design-system';
import React from 'react';

const minHeight = 24;

export type Props = {
  value: React.ReactNode;
  label: string;
  info?: string;
};

const ValueHighlight: React.FC<Props> = ({ value, label, info }) => (
  <Grid
    container
    display="flex"
    minHeight={minHeight}
    alignItems="center"
    columnGap={1}
    paddingLeft={4}
    paddingRight={2}
  >
    <Grid
      item
      display="flex"
      alignSelf="start"
      minHeight={minHeight}
      sx={{ padding: theme => `${theme.spacing(0.5)} 0` }}
      alignItems="center"
      width={theme => theme.spacing(17.875)}
      role="rowheader"
    >
      <Typography
        variant="specialLabel"
        color="text.secondary"
        paddingTop="1px"
        lineHeight={theme => theme.spacing(1.75)}
      >
        {label}
      </Typography>
      {info && (
        <Tooltip
          title={
            <Grid maxWidth={theme => theme.spacing(19.75)}>
              <Typography variant="tooltip">{info}</Typography>
            </Grid>
          }
          placement="top-start"
          variant="info"
        >
          <Typography color="text.secondary" paddingLeft={0.5} fontSize={12} display="flex">
            <InfoOutlinedIcon fontSize="inherit" />
          </Typography>
        </Tooltip>
      )}
    </Grid>
    <Grid
      item
      flex={1}
      role="cell"
      alignSelf="start"
      minHeight={minHeight}
      sx={{ padding: theme => `${theme.spacing(0.25)} 0` }}
    >
      {typeof value === 'string' ? <Typography variant="highlight2">{value}</Typography> : value}
    </Grid>
  </Grid>
);

export default ValueHighlight;
