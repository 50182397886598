import { Popover, Tag } from '@cmg/common';
import isNil from 'lodash/isNil';
import React from 'react';
import styled, { ThemeConsumer } from 'styled-components/macro';

import { getFeatureToggles } from '../../../config/appSettings';
import { getFormattedCurrency } from '../../shared/model/utils';
import { type Calendar_OfferingFieldsFragment, Country, OfferingStatus } from '../graphql';

const SPriceRangeWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const STagWrapper = styled.div`
  margin-top: -3px;
  margin-right: 5px;
`;

export type Props = {
  offering: Calendar_OfferingFieldsFragment;
};

const PriceRangeRenderer: React.FC<Props> = ({ offering }) => {
  const {
    revisedIpoRangeLowUsd,
    revisedIpoRangeHighUsd,
    initialIpoRangeLowUsd,
    initialIpoRangeHighUsd,
    latestIpoRangeLowUsd,
    latestIpoRangeHighUsd,
    pricingCurrency,
  } = offering.attributes || {};

  const isIpoPriceRangeRevised = !isNil(revisedIpoRangeLowUsd) && !isNil(revisedIpoRangeHighUsd);

  const hideRevisedTag =
    getFeatureToggles().isRestrictFiledUSOfferingsInDLOn &&
    offering.attributes?.exchangeCountry === Country.Us &&
    offering.attributes?.status === OfferingStatus.Filed;

  return (
    <SPriceRangeWrapper>
      {isIpoPriceRangeRevised &&
        initialIpoRangeLowUsd &&
        initialIpoRangeHighUsd &&
        !hideRevisedTag && (
          <Popover
            placement="top"
            variant="TOOLTIP"
            content={
              <span>
                Initial Range: {getFormattedCurrency(pricingCurrency, initialIpoRangeLowUsd)}
                {' \u2013 '}
                {getFormattedCurrency(pricingCurrency, initialIpoRangeHighUsd)}
              </span>
            }
          >
            <STagWrapper>
              <ThemeConsumer>{theme => <Tag color={theme.color.blue2}>R</Tag>}</ThemeConsumer>
            </STagWrapper>
          </Popover>
        )}
      <span>
        {latestIpoRangeLowUsd && latestIpoRangeHighUsd
          ? `${getFormattedCurrency(pricingCurrency, latestIpoRangeLowUsd)} \u2013
         ${getFormattedCurrency(pricingCurrency, latestIpoRangeHighUsd)}`
          : '-'}
      </span>
    </SPriceRangeWrapper>
  );
};

export default PriceRangeRenderer;
