import { checkPermissions, permissionsByEntity, useAuth } from '@cmg/auth';
import { Box } from '@cmg/common';
import { calendarContainerTestId } from '@cmg/e2e-selectors';
import React from 'react';
import { useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Redirect, Route, Switch } from 'react-router-dom';

import { PrivateRoute } from '../../../common/components/routing/private-route/PrivateRoute';
import { useDecisionShowLiveAndFiled } from '../../../common/hooks/useDecisionShowLiveAndFiled';
import routeFactory from '../../../common/util/routeFactory';
import PrecedentTransactionsModal from '../../shared/components/precedent-reports/PrecedentReportModal';
import ScreenContent from '../../shared/layout/ScreenContent';
import ScreenHeader from '../../shared/layout/ScreenHeader';
import { selectFilterValues } from '../ducks';
import { CalendarTabType, getCalendarTabs } from '../model/calendar-tabs';
import FiledOfferingsCalendar from '../tabs/FiledOfferingsCalendar';
import LiveOfferingsCalendar from '../tabs/LiveOfferingsCalendar';
import LockupExpirationsOfferingsCalendar from '../tabs/LockupExpirationsOfferingsCalendar';
import MyOfferingsCalendar from '../tabs/MyOfferingsCalendar';
import MyOfferingsWithAllocationsCalendar from '../tabs/MyOfferingsWithAllocationsCalendar';
import PostponedOfferingsCalendar from '../tabs/PostponedOfferingsCalendar';
import PricedOfferingsCalendar from '../tabs/PricedOfferingsCalendar';
import { SCalendarScreen, SContainer } from './CalendarContainer.styles';

export type Props = RouteComponentProps;

export const CalendarContainer: React.FC<Props> = ({ history }) => {
  const { userPermissions, oidcUserId } = useAuth();
  const showLiveAndFiled = useDecisionShowLiveAndFiled();

  const filters = useSelector(selectFilterValues);

  const handleOnTabChanged = (tab: CalendarTabType) => {
    history.push(tab.routePath);
  };

  const canViewIoi = checkPermissions(userPermissions, [permissionsByEntity.Ioi.READ]);
  const tabs = getCalendarTabs({ canViewIoi: canViewIoi, showLiveAndFiled: showLiveAndFiled });

  const tabProps = {
    filters,
    tabs,
    oidcUserId,
    onTabChange: handleOnTabChanged,
  };

  const defaultRoute = showLiveAndFiled
    ? routeFactory.calendarLiveOfferings.routePath
    : routeFactory.calendarPricedOfferings.routePath;

  return (
    <SCalendarScreen data-test-id={calendarContainerTestId.testId}>
      <ScreenHeader />
      <ScreenContent>
        <SContainer>
          <Box>
            {/* Datalab routes */}
            <Switch>
              {showLiveAndFiled && (
                <Route path={routeFactory.calendarLiveOfferings.routePath}>
                  <LiveOfferingsCalendar {...tabProps} />
                </Route>
              )}
              <Route path={routeFactory.calendarPricedOfferings.routePath}>
                <PricedOfferingsCalendar {...tabProps} />
              </Route>
              {showLiveAndFiled && (
                <Route path={routeFactory.calendarFiledOfferings.routePath}>
                  <FiledOfferingsCalendar {...tabProps} />
                </Route>
              )}
              <Route path={routeFactory.calendarPostponedOfferings.routePath}>
                <PostponedOfferingsCalendar {...tabProps} />
              </Route>
              <Route path={routeFactory.calendarLockupExpirationOfferings.routePath}>
                <LockupExpirationsOfferingsCalendar {...tabProps} />
              </Route>

              {/**
               * This route is mutually exclusive with MyOfferingsWithAllocationsCalendar
               * and should be accessible only if user does not heave READ permission
               */}
              <PrivateRoute
                path={routeFactory.calendarMyOfferings.routePath}
                renderNoAccess={() => <Redirect to={routeFactory.forbidden.routePath} />}
                accessCheck={userPermissions =>
                  !checkPermissions(userPermissions, [permissionsByEntity.Ioi.READ])
                }
                render={() => <MyOfferingsCalendar {...tabProps} />}
              />
              <PrivateRoute
                path={routeFactory.calendarMyOfferingsWithAllocation.routePath}
                requiredPermissions={[permissionsByEntity.Ioi.READ]}
                renderNoAccess={() => <Redirect to={routeFactory.forbidden.routePath} />}
                render={() => <MyOfferingsWithAllocationsCalendar {...tabProps} />}
              />

              <Redirect to={defaultRoute} />
            </Switch>
          </Box>
        </SContainer>
      </ScreenContent>
      <PrecedentTransactionsModal />
    </SCalendarScreen>
  );
};

export default CalendarContainer;
