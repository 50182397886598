import React from 'react';

import { Country } from '../../../../../../graphql/__generated__';
import {
  getFormattedCurrency,
  getFormattedCurrencyInMillion,
  getFormattedCurrencyValue,
  getFormattedPercentageValue,
} from '../../../../../shared/model/utils';
import { OfferingProfile_ListQuery } from '../../../graphql/__generated__/OfferingProfile';
import {
  CardRowItem,
  emptyValue,
  OfferingProfileFieldName,
  UnderwriterTermsSectionData,
} from '../types';

const getUnderwriterCurrencyValue = (
  value: number | null | undefined,
  pricingCurrencyCode?: string | undefined
) => {
  return value
    ? `${getFormattedCurrencyValue({
        value,
        pricingCurrencyCode,
      })}/share`
    : '-';
};

export const getUnderwriterTermsData = (
  offeringProfile: OfferingProfile_ListQuery
): CardRowItem[] => {
  const pricingCurrencyCode = 'USD';
  const { attributes, exchangeCountry } = offeringProfile.offeringById ?? {};
  const indentation = '\xa0\xa0\xa0\xa0\xa0\xa0\xa0';
  const {
    netPrice,
    estimatedFeeUsd,
    pctGrossSpread,
    grossSpreadTotal,
    sellingConcessionUsd,
    managementFeeUsd,
    underwritingFeeUsd,
  } = attributes ?? {};
  return [
    {
      name: OfferingProfileFieldName.NetPrice,
      value: '',
      value2: getFormattedCurrency(pricingCurrencyCode, netPrice),
    },
    {
      name: OfferingProfileFieldName.EstimatedFee,
      value: '',
      value2: getFormattedCurrencyInMillion(pricingCurrencyCode, estimatedFeeUsd),
    },
    {
      name: OfferingProfileFieldName.GrossSpread,
      value: getFormattedPercentageValue(pctGrossSpread) ?? emptyValue,
      value2: getUnderwriterCurrencyValue(grossSpreadTotal, pricingCurrencyCode),
    },
    ...(exchangeCountry !== Country.Us
      ? []
      : [
          {
            name: `${indentation}${OfferingProfileFieldName.SellingConcessionUsd}`,
            value: '60.00%',
            value2: getUnderwriterCurrencyValue(sellingConcessionUsd, pricingCurrencyCode),
          },
          {
            name: `${indentation}${OfferingProfileFieldName.ManagementFee}`,
            value: '20.00%',
            value2: getUnderwriterCurrencyValue(managementFeeUsd, pricingCurrencyCode),
          },
          {
            name: `${indentation}${OfferingProfileFieldName.UnderwritingFee}`,
            value: '20.00%',
            value2: getUnderwriterCurrencyValue(underwritingFeeUsd, pricingCurrencyCode),
          },
        ]),
  ];
};
export const getUnderwriterNamesData = (
  offeringProfile: OfferingProfile_ListQuery
): CardRowItem[] => {
  const { attributes } = offeringProfile.offeringById ?? {};
  const { leftLeadName, stabilizationAgentName, settlementAgentName } = attributes ?? {};
  return [
    {
      name: OfferingProfileFieldName.LeftLead,
      value: leftLeadName ?? emptyValue,
    },
    {
      name: OfferingProfileFieldName.SettlementAgent,
      value: settlementAgentName ?? emptyValue,
    },
    {
      name: OfferingProfileFieldName.StabilizationAgent,
      value: stabilizationAgentName ?? emptyValue,
    },
  ];
};

export const getUnderwriterPctData = (
  offeringProfile: OfferingProfile_ListQuery
): CardRowItem[] => {
  const { attributes } = offeringProfile.offeringById ?? {};
  const {
    totalPctToActiveManagers,
    totalPctToBookrunners,
    totalPctToNonBookrunners,
    totalPctToLeftLead,
  } = attributes ?? {};
  return [
    {
      name: OfferingProfileFieldName.PctToActiveBookrunners,
      value: getFormattedPercentageValue(totalPctToActiveManagers) ?? emptyValue,
    },
    {
      name: OfferingProfileFieldName.PctToBookrunners,
      value: getFormattedPercentageValue(totalPctToBookrunners) ?? emptyValue,
    },
    {
      name: OfferingProfileFieldName.PctToNonBookrunners,
      value: getFormattedPercentageValue(totalPctToNonBookrunners) ?? emptyValue,
    },
    {
      name: OfferingProfileFieldName.PctToLeftLead,
      value: getFormattedPercentageValue(totalPctToLeftLead) ?? emptyValue,
    },
  ];
};

export const useGetUnderwriterTermsSectionData = (
  offeringProfile: OfferingProfile_ListQuery
): UnderwriterTermsSectionData =>
  React.useMemo(() => {
    const underwriterTerms = getUnderwriterTermsData(offeringProfile);
    const underwriterNames = getUnderwriterNamesData(offeringProfile);
    const underwriterPct = getUnderwriterPctData(offeringProfile);
    return {
      underwriterTerms,
      underwriterNames,
      underwriterPct,
    };
  }, [offeringProfile]);
