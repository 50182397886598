import { numericUtil } from '@cmg/common';
import isNumber from 'lodash/isNumber';
import React from 'react';

import {
  emptyValueTypography,
  getFormattedPercentageValue,
} from '../../../../../shared/model/utils';
import { OfferingProfile_ListQuery } from '../../../graphql/__generated__/OfferingProfile';

export const useGetShareholdersData = (offeringProfile: OfferingProfile_ListQuery) =>
  React.useMemo(() => {
    const { shareholders: shareholdersData } = offeringProfile.offeringById ?? {};

    const shareholders = (shareholdersData ?? []).map(shareholder => ({
      name: shareholder.name,
      type: shareholder.isCarveOutParent
        ? 'Carve Out Parent'
        : shareholder.shareholderTypeDisplayName,
      preOfferingShares: isNumber(shareholder.preOfferingOwnership)
        ? numericUtil.getDisplayValueForInteger(shareholder.preOfferingOwnership)
        : emptyValueTypography,
      preOfferingPct: isNumber(shareholder.pctPreOfferingOwnership)
        ? getFormattedPercentageValue(shareholder.pctPreOfferingOwnership)
        : emptyValueTypography,
      postOfferingShares: isNumber(shareholder.postOfferingOwnership)
        ? numericUtil.getDisplayValueForInteger(shareholder.postOfferingOwnership)
        : emptyValueTypography,
      postOfferingPct: isNumber(shareholder.pctPostOfferingOwnership)
        ? getFormattedPercentageValue(shareholder.pctPostOfferingOwnership)
        : emptyValueTypography,
      postOfferingOvlt: isNumber(shareholder.postOfferingOwnershipIncludingShoe)
        ? numericUtil.getDisplayValueForInteger(shareholder.postOfferingOwnershipIncludingShoe)
        : emptyValueTypography,
      postOfferingOvltPct: isNumber(shareholder.pctPostOfferingOwnershipIncludingShoe)
        ? getFormattedPercentageValue(shareholder.pctPostOfferingOwnershipIncludingShoe)
        : emptyValueTypography,
    }));

    return { shareholders };
  }, [offeringProfile.offeringById]);
