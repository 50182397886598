import { Typography } from '@cmg/design-system';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import React from 'react';

export type Props = {
  value?: boolean | null;
};

const BooleanIcon: React.FC<Props> = ({ value }) => {
  switch (value) {
    case true:
      return <CheckIcon color="success" fontSize="small" />;
    case false:
      return <ClearIcon color="error" fontSize="small" />;
    default:
      return <Typography paddingLeft={0.5}>-</Typography>;
  }
};

export default BooleanIcon;
