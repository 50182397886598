import isNull from 'lodash/isNull';
import isUndefined from 'lodash/isUndefined';
import React from 'react';

import { OfferingProfile_ListQuery } from '../../../graphql/__generated__/OfferingProfile';
import { CardRowItem } from '../types';

export const useGetAdvisersData = (offeringProfile: OfferingProfile_ListQuery): CardRowItem[] =>
  React.useMemo(() => {
    const { advisers } = offeringProfile.offeringById ?? {};
    if (!advisers) {
      return [];
    }
    return advisers
      .filter(({ roleDisplayName }) => {
        return !isNull(roleDisplayName) && !isUndefined(roleDisplayName);
      })
      .sort((a, b) => {
        return a.roleDisplayName! > b.roleDisplayName! ? 1 : -1;
      })
      .map(adviser => {
        return {
          name: adviser.roleDisplayName!,
          value: adviser.name,
        };
      });
  }, [offeringProfile.offeringById]);
